import React from 'react';
import { graphql } from 'gatsby';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const TermOfUse: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground>
        <PageTitle subtitle="" title="Term Of Use" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">Willkommen auf der Website von Ironhack </Text3>
          <Text3 as="p">
            Willkommen auf der Website von Ironhack. Wir danken Ihnen für Ihren
            Besuch und hoffen, dass Sie das Surferlebnis genießen.
          </Text3>
          <Text3 as="p">
            Ironhack, Inc. (“<strong>Ironhack</strong>”, “<strong>wir</strong>”,
            “<strong>uns</strong>” oder “<strong>unser</strong>”) ist ein
            internationaler Anbieter von Kursen über Webentwicklung und UX/UI
            Design mit Campus in verschiedenen Teilen der Welt. Diese
            Nutzungsbedingungen (“<strong>Bedingungen</strong>”) gelten für die
            Website des Unternehmens Ironhack, die unter{' '}
            <a href="https://www.ironhack.com/de">https://www.ironhack.com</a>{' '}
            aufrufbar ist, seine Ausbildungszentren in der ganzen Welt sowie
            seine Filialen und Partner. Diese Bedingungen stellen einen
            rechtskräftigen Vertrag zwischen Ihnen (“<strong>Sie</strong>”, “
            <strong>Ihnen</strong>”, “<strong>Ihr</strong>”) und uns dar und
            regeln die Nutzung aller Texte, Daten, Information, Software,
            Grafiken, Fotografien und sonstigen Elemente (die wir “
            <strong>Materialien</strong>” nennen), die sowohl wir als auch
            unsere Filialen und Partner Ihnen zur Verfügung stellen, und auch
            die Nutzung jeder Dienstleistung (“<strong>Dienstleistungen</strong>
            ”), die wir auf unserer Website anbieten (all dies wird in diesen
            Bedingungen als “<strong>Website</strong>” bezeichnet).
          </Text3>
          <Text3 as="p">
            LESEN SIE DIESE BEDINGUNGEN AUFMERKSAM DURCH, BEVOR SIE AUF DIESER
            WEBSITE SURFEN. DURCH DIE NUTZUNG DIESER WEBSITE BRINGEN SIE ZUM
            AUSDRUCK, DASS SIE DIESE BEDINGUNGEN GELESEN HABEN UND SIE
            AKZEPTIEREN. SIE KÖNNEN DIESE WEBSITE NICHT NUTZEN, WENN SIE DIESE
            BEDINGUNGEN NICHT AKZEPTIEREN. SOLLTEN SIE NICHT DAMIT EINVERSTANDEN
            SEIN, SURFEN SIE BITTE NICHT AUF DIESER WEBSITE.
          </Text3>
          <Text3 as="p">
            ANMERKUNG: DIESE BEDINGUNGEN ENTHALTEN EINE KLAUSEL ÜBER DIE
            BEILEGUNG VON STREITIGKEITEN UND SCHIEDSVERFAHREN, EINSCHLIESSLICH
            DES VERZICHTS AUF EINE SAMMELKLAGE, DER SICH KRAFT DIESER
            BEDINGUNGEN AUF IHRE RECHTE AUSWIRKT, UND ZWAR IM ZUSAMMENHANG MIT
            STREITIGKEITEN JEDER ART, DIE ES ZWISCHEN IHNEN UND DEM UNTERNEHMEN
            GEBEN KÖNNTE. WIE WEITER UNTEN FESTGESETZT IST, KÖNNEN SIE AUF DAS
            BINDENDE INDIVIDUELLE SCHIEDSVERFAHREN UND AUF DIE SAMMELKLAGE
            VERZICHTEN.
          </Text3>
          <Text3 as="p">
            Ironhack behält sich das Recht vor, Teile dieser Bedingungen nach
            freiem Ermessen und jederzeit zu ändern, zu löschen oder neue
            hinzuzufügen. Überprüfen Sie bitte diese Bedingungen regelmäßig.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ÄNDERUNGEN</Text2>
          <Text3 as="p">
            Wir können nach freiem Ermessen die Materialien und
            Dienstleistungen, die wir bieten, ändern oder beschließen, diese
            Website jederzeit und ohne vorherige Ankündigung zu ändern, zu
            sperren oder zu unterbrechen. Gelegentlich können wir auch Klauseln
            dieser Bedingungen ändern, aktualisieren, hinzufügen oder entfernen
            (gemeinsam als die “Änderungen” bezeichnet). Alles klar und deutlich
            darzulegen ist für alle von Vorteil, und somit versprechen wir, Sie
            von jeder Änderung dieser Bedingungen in Kenntnis zu setzen, indem
            wir einen diesbezüglichen Hinweis auf dieser Website
            veröffentlichen. &nbsp;
          </Text3>
          <Text3 as="p">
            Wenn Sie mit solchen Änderungen nicht einverstanden sind, können Sie
            nur die Nutzung dieser Website einstellen. Sollten Sie die Website
            nach Bekanntgabe der besagten Änderungen weiter nutzen, gehen wir
            davon aus, dass Sie anerkennen und azeptieren, dass sie für Sie
            verbindlich sind, und dass Sie damit einverstanden sind.
            Berücksichtigen Sie bitte auch, dass diese Bedingungen durch
            ausdrücklich hervorgehobene rechtliche Hinweise oder Bedingungen
            ersetzt werden können, die sich auf konkreten Seiten dieser Website
            befinden. Die besagten ausdrücklich hervorgehobenen rechtlichen
            Hinweise oder Bedingungen werden in diese Bedingungen aufgenommen
            und ersetzen die dementsprechenden Klauseln dieser Bedingungen.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ALLGEMEINE NUTZUNG</Text2>
          <Text3 as="p">
            Durch Nutzung dieser Website sichern Sie zu, dass Sie mindestens 18
            Jahre alt sind. Sollten Sie noch nicht 18 Jahre alt sein, müssen Sie
            für die Nutzung dieser Website und die Akzeptierung ihrer
            Bedingungen die Erlaubnis eines Erwachsenen einholen und es muss
            sich dabei um ein Elternteil oder den gesetzlichen Vormund handeln.
            Und diese Person übernimmt die Verantwortung für Ihre Nutzung dieser
            Website.
          </Text3>
          <Text3 as="p">
            Nutzen Sie diese Website für Ihren persönlichen Gebrauch (“
            <strong>zugelassene Zwecke</strong>”). Viel Vergnügen!
          </Text3>
          <Text3 as="p">
            Anhand dieser Bedingungen erteilen wir Ihnen eine beschränkte,
            persönliche, nicht ausschließliche und nicht übertragbare Lizenz für
            die Nutzung und Visualisierung der Materialien. Ihr Recht auf die
            Nutzung der Materialien setzt voraus, dass Sie diese Bedingungen
            erfüllen. Sie haben keine sonstigen Rechte an dieser Website oder an
            den Materialien und dürfen nichts an dieser Website und den
            Materialien ändern, editieren, kopieren, wiedergeben, modifizieren,
            verbessern oder in irgendeiner Weise verwerten, keine darauf
            basierende Arbeiten erstellen und keine Techniken des Reverse
            Engineering darauf anwenden. Sollten Sie einen Teil dieser Website
            im Verlauf Ihrer Teilnahme im Rahmen der zugelassenen Zwecke
            kopieren, empfehlen wir Ihnen, Kopie aller unserer Urheberrechte und
            der sonstigen Eigentumsrechte, so wie sie auf dieser Website
            erscheinen, aufzubewahren. &nbsp;
          </Text3>
          <Text3 as="p">
            Sollten Sie jedoch gegen eine dieser Bedingungen verstoßen, verfällt
            die oben genannte Lizenz automatisch und Sie müssen unverzüglich
            alle Materialien vernichten, die Sie heruntergeladen oder
            ausgedruckt haben (und auch alle davon angefertigten Kopien).
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            NUTZUNG DIESER WEBSITE UND UNSERER DIENSTLEISTUNGEN
          </Text2>
          <Text3 as="p">
            Wir danken Ihnen, dass Sie unsere Website besuchen, und lassen Sie
            “eintreten”, damit Sie alles sehen können, ganz unverbindlich und
            ohne sich registrieren zu müssen.
          </Text3>
          <Text3 as="p">
            Um jedoch Zugang zu bestimmten Dienstleistungen, unseren Ausbildern
            und den spezifischen Materialien eines Kurses zu haben, müssen Sie
            sich zuerst eintragen und danach in einem unserer Bootcamps über
            Webentwicklung oder UX/UI Design oder unseren Part Time Programmen
            aufgenommen werden (“<strong>Ironhack Bootcamp</strong>”).
          </Text3>
          <Text3 as="p">
            Für bestimmte Dienstleistungen oder Teile davon und für spezifische
            Funktionen der Website können zusätzliche Bedingungen und
            Bestimmungen festgesetzt sein. Mit der Anmeldung zu einer unserer
            Dienstleistungen akzeptieren Sie diese Bedingungen und Bestimmungen.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IRONHACK-ANMELDUNG</Text2>
          <Text3 as="p">
            Wenn Sie sich für ein Ironhack Bootcamp anmelden möchten, müssen Sie
            auf die Schaltfläche “
            <a href="https://www.ironhack.com/de/kurse/bewerben">Apply now</a>”
            ("Anmeldung") und die folgenden Informationen übermitteln :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Vorname und Nachname;</li>
              <li>Telefonnummer;</li>
              <li>Aktive E-Mail Adresse;</li>
              <li>Geschlecht;</li>
              <li>Referenz; und</li>
              <li>
                Eine kurze Beschreibung, warum Sie an Ironhack interessiert
                sind.
              </li>
            </ul>
            Während des Registrierungsprozesses können Sie auch zusätzliche
            optionale Informationen angeben, damit wir Ihnen eine persönlichere
            Erfahrung bei der Nutzung dieser Website bieten können, aber das
            überlassen wir Ihnen.
          </Text3>
          <Text3 as="p">
            Sobald Sie die angeforderten Informationen eingereicht haben, werden
            wir entscheiden, ob wir Ihre Anmeldung annehmen oder nicht. Im Falle
            einer Genehmigung erhalten Sie eine E-Mail, in der Sie über die
            notwendigen Schritte zum Abschluss des Anmeldeverfahrens informiert
            werden. Wenn Sie die oben genannten Informationen nicht übermitteln,
            können wir Ihr Anmeldeformular nicht bearbeiten oder Ihnen unsere
            Dienste nicht in der bestmöglichen Weise zur Verfügung stellen.
          </Text3>
          <Text3 as="p">
            Wenn Sie auf die Website zugreifen, sind Sie dafür verantwortlich,
            diese Bedingungen einzuhalten. Es liegt in Ihrer Verantwortung, die
            für den Zugang und die Nutzung dieser Website erforderlichen Geräte
            und technischen Dienste zu beschaffen und zu unterhalten und die
            damit verbundenen Gebühren zu entrichten. Es liegt auch in Ihrer
            Verantwortung, die Vertraulichkeit der Informationen zu wahren, die
            Sie uns während Ihrer Anmeldung zum Bootcamp Ironhack zur Verfügung
            stellen. Wenn Sie glauben, dass Ihre persönlichen Daten oder die
            Sicherheit in Bezug auf diese Website in irgendeiner Weise verletzt
            wurden, müssen Sie uns unverzüglich benachrichtigen. Wenn Sie
            weitere Informationen benötigen, besuchen Sie bitte die Seite zum{' '}
            <a href="https://www.ironhack.com/de/rechtlicher-hinweis/datenschutzerklaerung">
              Datenschutzpolitik
            </a>
            .
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ELEKTRONISCHE KOMMUNIKATIONEN</Text2>
          <Text3 as="p">
            Durch Nutzung dieser Website erklären Sie sich damit einverstanden,
            elektronische Kommunikationen von uns zu erhalten, einschließlich
            Benachrichtigungen per E-Mail. Diese elektronischen Kommunikationen
            können Bekanntgaben von anwendbaren Gebühren und Kosten, Information
            über Transaktionen und sonstige mit der Website oder unseren Kursen
            in Verbindung stehende Information enthalten. Sie sind Bestandteil
            der Beziehung, die Sie mit uns verbindet. Sie sind damit
            einverstanden, dass jede Mitteilung, jede Vereinbarung, jede
            Verbreitung oder sonstige Kommunikationen, die wir Ihnen auf
            elektronischem Weg senden, alle gesetzlichen Erfordernisse in Bezug
            auf Kommunikationen erfüllen, und auch, dass die Kommunikationen
            schriftlich erfolgen.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DATENSCHUTZPOLITIK</Text2>
          <Text3 as="p">
            Wir respektieren die Information, die Sie uns übermitteln, und
            wollen sicherstellen, dass Sie genau wissen, wie wir diese
            Information verwenden. Lesen Sie bitte unsere “
            <strong>Datenschutzerklärung</strong>”, in der alles erklärt wird.
            Durch Nutzung dieser Website akzeptieren Sie und erkennen an, dass
            die Übertragungen über das Internet nie völlig sicher oder privat
            sind. Vergessen Sie nie, dass jede Information, die über oder an die
            Website gesendet wird, von Dritten abgefangen und gelesen werden
            kann, auch wenn sie verschlüsselt ist.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LINKS ZU SITES VON DRITTEN</Text2>
          <Text3 as="p">
            Vielleicht wollen Sie uns abonnieren, über die sozialen Netzwerke
            mit uns verbunden sein oder auf andere Webseiten zugreifen, auf die
            wir auf unserer Blog-Seite oder Website Bezug nehmen. Wir sind der
            Meinung, dass Links nützlich sind, und manchmal setzen wir auf
            dieser Website Links zu Websites von Dritten. Sie verlassen diese
            Website, wenn Sie diese Links verwenden. Wir sind nicht
            verpflichtet, die Websites von Dritten zu überprüfen, auf die man
            von dieser Website aus gelangen kann, wir kontrollieren nicht die
            Websites von Dritten und sind für keine Website von Dritten (und
            auch nicht für die Produkte, Dienstleistungen oder Inhalte, die über
            eine solche Website zur Verfügung stehen) verantwortlich. Folglich
            leisten wir keine Gewähr für solche Websites von Dritten, auch nicht
            für die Information, den Inhalt, die Software, die Produkte,
            Dienstleistungen oder Materialien, die sich darauf befinden, oder
            die Ergebnisse, die man aus deren Nutzung erreichen kann, und
            vertreten sie nicht. Sollten Sie beschließen, eine der Websites
            aufzurufen, auf die man über einen Link von dieser Website aus
            gelangen kann, so geschieht dies auf eigene Gefahr und Sie müssen
            die Datenschutzbestimmungen und die Bedingungen dieser Websites von
            Dritten erfüllen.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SENDUNGEN</Text2>
          <Text3 as="p">
            Bestimmte Bereiche dieser Website (zum Beispiel, die Funktion
            Kommentare im Blog) können Ihnen erlauben, Meinungen, Information,
            Daten, Text, Nachrichten oder sonstige Materialien zu senden (“
            <strong>Nutzereinsendungen</strong>”). Sie sind damit einverstanden,
            der einzige Verantwortliche für alle Nutzereinsendungen zu sein, und
            dass diese Nutzereinsendungen als nicht vertraulich und keinen
            Eigentumsrechten unterliegend gelten. Außerdem garantieren wir
            nicht, dass Sie eine der von Ihnen geschickten Nutzereinsendungen
            editieren oder löschen können.
          </Text3>
          <Text3 as="p">
            Der Umstand, dass Sie eine Nutzereinsendung schicken, bringt mit
            sich, dass Sie uns Folgendes versprechen:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Dass Sie Inhaber aller Rechte an Ihren Nutzereinsendungen sind
                (u. a. einschließlich aller Wiedergabe- und
                Veröffentlichungsrechte Ihrer Nutzereinsendungen) oder sonst
                alle erforderlichen Rechte an Ihren Nutzereinsendungen erworben
                haben, die es Ihnen erlauben, uns die Rechte an Ihren
                Nutzereinsendungen, so wie sie in diesen Bedingungen beschrieben
                sind, zu gewähren;
              </li>
              <li>
                Dass Sie alle Lizenz- und Zollgebühren gezahlt haben und zahlen
                werden sowie den sonstigen Zahlungsverpflichtungen jeder Art
                nachgekommen sind und nachkommen werden, die aufgrund der
                Nutzung und gewerblichen Verwertung Ihrer Nutzereinsendungen
                anfallen;
              </li>
              <li>
                Dass Ihre Nutzereinsendungen Urheberrechte, eingetragene
                Warenzeichen, Patente, Geschäftsgeheimnisse oder sonstige Rechte
                an geistigem Eigentum, Datenschutzrechte oder Gesetzes- oder
                Persönlichkeitsrechte von Dritten nicht verletzen;
              </li>
              <li>
                Dass Sie freiwillig auf alle “Persönlichkeitsrechte” verzichten,
                die Sie an Ihren Nutzereinsendungen haben könnten;
              </li>
              <li>
                Dass es Ihnen nicht bekannt ist, dass die in Ihren
                Nutzereinsendungen enthaltene Information falsch, unrichtig oder
                trügerisch ist;
              </li>
              <li>
                Dass Ihre Nutzereinsendungen gegen kein Gesetz verstoßen (u. a.
                jene, die für die Kontrolle der Exporte, den Verbraucherschutz,
                den unlauteren Wettbewerb, die Maßnahmen zur Bekämpfung von
                Diskriminierungen oder falsche Propaganda gelten);
              </li>
              <li>
                Dass Ihre Nutzereinsendungen in Bezug auf Rasse sowie aus
                ethnischer oder religiöser Sicht nicht diffamierend,
                verleumderisch, bedrohlich, tendenziös oder beileidigend sind
                und in vertretbarer Weise nicht als solche gelten können, keine
                Drohung oder illegalen Zwang für eine Person, eine Vereinigung
                oder Körperschaft darstellen, und nicht vulgär, pornographisch,
                obszön sind und auch nicht in die Privatsphäre von anderen
                Personen eindringen;
              </li>
              <li>
                Dass Sie für Ihre Nutzereinsendungen keine Vergütung erhalten
                und nicht als Dritter gelten;
              </li>
              <li>
                Dass Ihre Nutzereinsendungen kein Material aus Websites von
                Dritten oder Postanschriften, E-Mail-Adressen,
                Kontaktinformation oder Telefonnummern enthalten (mit Ausnahme
                Ihrer eigenen);
              </li>
              <li>
                Dass Ihre Nutzereinsendungen keine Computerviren, Computerwürme,
                Spyware, Adware oder sonstigen potenziell gefährlichen Programme
                oder Dateien enthalten;
              </li>
              <li>
                Dass Ihre Nutzereinsendungen keine vertrauliche oder mit
                Eigentumsrechten geschützte oder persönliche Information
                enthalten; und
              </li>
              <li>
                Dass Ihre Nutzereinsendungen keine unerwünschte oder nicht
                autorisierte Werbung, Promotionmaterial, Junkmail, Spam,
                Kettenbriefe, Schneeballsysteme oder keine sonstige Form von
                Teilnahmeaufrufen enthalten oder darstellen.
              </li>
            </ul>
            Wenn Sie uns eine Nutzereinsendung schicken, bedeutet dies, dass Sie
            uns eine unwiderrufliche, unkündbare, übertragbare, nicht
            ausschließliche, voll bezahlte, internationale und kostenfreie
            Lizenz erteilen (für die ihrerseits auf mehreren Ebenen
            Unterlizenzen gewährt werden können):
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                um Ihre Nutzereinsendungen (oder alle ihre geänderten Versionen)
                ganz oder teilweise in jedem zurzeit bekannten Format oder
                Medium oder einem solchen, das in der Zukunft entwickelt wird,
                zu verwenden, zu verteilen, wiederzugeben, zu ändern,
                anzupassen, zu veröffentlichen, zu übersetzen, öffentlich
                darzustellen und öffentlich zu zeigen;
              </li>
              <li>
                Um Ihre Nutzereinsendungen in jeder Weise und zu jedem Zweck (u.
                a. für kommerzielle Zwecke), die wir nach unserem
                ausschließlichen Ermessen als angemessen betrachten (u. a. die
                Aufnahme Ihrer Nutzereinsendungen oder jede ihrer Änderungen
                ganz oder teilweise in jede Art von Technologie, Produkt oder
                Dienstleistung), zu verwenden (und zu erlauben, dass andere sie
                verwenden);
              </li>
              <li>
                Um im Zusammenhang mit Ihren Nutzereinsendungen stehende Werbung
                zu zeigen und Ihre Nutzereinsendungen für Werbe- und
                Promotionzwecke zu verwenden.
              </li>
            </ul>
            Wir erlauben keine gefährlichen oder offensiven Nutzereinsendungen
            auf unserer Website. Wir können – sind aber nicht dazu verpflichtet
            – die Nutzereinsendungen einer vorherigen Auswahl unterwerfen oder
            jede Stelle dieser Website kontrollieren, über die
            Nutzereinsendungen geschickt werden können. Wir sind nicht
            verpflichtet, eine Nutzereinsendung auf bzw. über diese Website zu
            setzen, zu veröffentlichen oder zu verbreiten und wir können jede
            Nutzereinsendung – aus welchen Gründen auch immer – jederzeit
            löschen oder ablehnen. Wir sind nicht verantwortlich für Verlust,
            Raub oder Diebstahl – egal in welcher Form - der Nutzereinsendungen.
            Außerdem geben Sie ihre Einwilligung, damit wir Ihre
            Nutzereinsendungen in freier Weise an Dritte ohne
            Geheimhaltungspflicht seitens des Empfängers weitergeben.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">NICHT AUTORISIERTE TÄTIGKEITEN</Text2>
          <Text3 as="p">
            Damit keine Zweifel bestehen, erklären wir, dass wir Ihnen nur
            erlauben, diese Website für die zugelassenen Zwecke zu verwenden.
            Jede Verwendung dieser Website, die über die zugelassenen Zwecke
            hinaus geht, ist verboten und stellt eine nicht zugelassene
            Verwendung dieser Website dar, da im Rahmen desjenigen, was Sie und
            uns betrifft, alle Rechte an dieser Website uns gehören.
          </Text3>
          <Text3 as="p">
            Eine nicht autorisierte Verwendung dieser Website kann als Verstoß
            gegen die internationalen Gesetze über geistiges Eigentum (und die
            einiger Staaten der USA) gelten. Da wir wünschen, dass diese
            Beziehung so einwandfrei wie möglich ist, führen wir Beispiele an,
            damit Sie wissen, was Sie vermeiden sollen. Sollten Sie also keine
            gegenteilige Erlaubnis von uns haben, dürfen Sie diese Website nicht
            für Folgendes verwenden (nachstehend sind nur Beispiele angeführt
            und es handelt sich nicht um eine komplette Liste von demjenigen,
            was Sie nicht tun dürfen):
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Für eine öffentliche oder kommerzielle Nutzung, bei der diese
                Website auf einer anderen Site oder über eine Netzwerkumgebung
                verwendet wird;
              </li>
              <li>
                In einer Weise, mit der etwas auf dieser Website verändert,
                öffentlich gezeigt, öffentlich ausgeführt, wiedergegeben oder
                verteilt wird;
              </li>
              <li>
                In einer Weise, mit der gegen ein Gesetz, eine Vorschrift, eine
                Regel, eine Verordnung, ein Abkommen oder eine Gesetzgebung
                lokaler, staatlicher, in-, ausländischer oder internationaler
                Art verstoßen wird;
              </li>
              <li>
                Um jemanden anderen zu belästigen, zu bedrängen oder Schaden
                zuzufügen;
              </li>
              <li>
                Um sich für eine andere Person oder Einrichtung auszugeben oder
                in sonstiger Weise Ihre Beziehung zu einer Person oder
                Einrichtung zu verfälschen;
              </li>
              <li>
                Um die Betriebsweise dieser Website oder der damit verbundenen
                Server oder Netze zu beeinträchtigen oder zu stören;
              </li>
              <li>
                Um Data-Mining, Roboter oder ähnliche Methoden für die Erfassung
                oder Erhebung von Daten, die mit dieser Website in Verbindung
                stehen, zu verwenden; oder &nbsp;
              </li>
              <li>
                Um zu versuchen, durch Hacking, Passwort-Mining oder auf andere
                Weise unbefugten Zugriff auf eine Sektion dieser Website oder
                ein anderes Konto, Computersystem oder mit dieser Website
                verbundene Netze zu erhalten.
              </li>
            </ul>
            Sie sind damit einverstanden, Anwälte zu unserer Verteidigung zu
            beauftragen, wenn Sie gegen diese Bedingungen verstoßen und der
            Verstoß für uns zu einem Problem wird. Sie sind gleichfalls damit
            einverstanden, alle Kosten zu übernehmen, die wir infolge Ihrer
            Zuwiderhandlungen zahlen müssen. Sie allein sind für die
            Zuwiderhandlungen verantwortlich, die Sie in Bezug auf diese
            Bedingungen begehen. Wir behalten uns das Recht vor, die
            ausschließliche Verteidigung und Kontrolle jeder Angelegenheit zu
            übernehmen, die in irgendeiner anderen Weise mit einer Entschädigung
            Ihrerseits verbunden ist, und in einem solchen Fall sind Sie damit
            einverstanden, angesichts einer solchen Klage mit unserer
            Verteidigung zusammen zu arbeiten.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">EIGENTUMSRECHTE</Text2>
          <Text3 as="p">
            “Ironhack” ist eine von uns eingetragene Marke. Die anderen Marken,
            Namen und Logos auf dieser Website gehören ihren respektiven
            Inhabern.
          </Text3>
          <Text3 as="p">
            Wenn in diesen Bedingungen nicht das Gegenteil angegeben ist, so
            gehören alle Materialien, einschließlich ihrer Bereitstellung auf
            dieser Website, ausschließlich uns, Copyright © 2018. Alle in diesem
            Dokument nicht explizit garantierten Rechte sind vorbehalten. Jede
            Wiedergabe, Verteilung, Veränderung, Übertragung oder
            Veröffentlichung des gesamten mit Rechten an geistigem Eigentum
            geschützten Materials ist ohne ausdrückliche schriftliche
            Einwilligung des Inhabers der Rechte an geistigem Eigentum oder der
            Lizenz verboten, es sei denn, es gibt anwendbare Gesetze, die dies
            vorschreiben.
          </Text3>
          <Text3 as="p">
            Bevor Sie eine Handelsmarke, einen Namen, ein Logo oder einen Inhalt
            in welcher Form auch immer verwenden oder wiedergeben, müssen Sie
            mit uns in Kontakt treten und uns um Erlaubnis bitten. Sollten Sie
            ohne eine solche Erlaubnis handeln, würde dieses Vorgehen einen
            Verstoß gegen die internationalen Gesetze darstellen, die den
            Urheber oder Inhaber der Handelsmarke, des Namens oder des Logos
            oder jedes sonstigen Inhalts schützen, und Ihnen können spezifische
            finanzielle oder strafrechtliche Sanktionen auferlegt werden.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">VERLETZUNG VON RECHTEN AN GEISTIGEM EIGENTUM</Text2>
          <Text3 as="p">
            Wir respektieren die Rechte des geistigen Eigentums anderer und
            erwarten von Ihnen, dass Sie dies auch tun. Wir haben uns folglich
            vorgenommen, alle Nutzereinsendungen zu löschen, die die Rechte des
            geistigen Eigentums anderer verletzen, und werden dem Nutzer, der
            bei der Nutzung dieser Website gegen die Rechte des geistigen
            Eigentums anderer verstößt, den Zugang zu dieser Website (oder einem
            Teil davon) sperren oder, wenn die Umstände dies erfordern, das
            Ironhack Bootcamp für jeden Nutzer sperren, der bei der Nutzung
            dieser Website gegen die Rechte des geistigen Eigentums anderer
            verstößt. Kraft Titel 17 des United States Codes haben wir Verfahren
            eingeführt, um die Klagen auf Verletzung der Rechte des geistigen
            Eigentums schriftlich zu zugestellt zu bekommen und solche Klagen in
            Übereinstimmung mit den Gesetzen zu bearbeiten. Sollten Sie der
            Meinung sein, dass ein Nutzer dieser Website Ihre Urheberrechte oder
            andere Rechte des geistigen Eigentums verletzt hat, müssen Sie
            Ironhack schriftlich davon in Kenntnis setzen:
          </Text3>
          <Text3 as="p">A/A: DMCA-Vertreter von Ironhack</Text3>
          <Text3 as="p">
            E-Mail: <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
          <Text3 as="p">
            Damit sichergestellt ist, dass dieses Thema unverzüglich bearbeitet
            wird, muss die schriftliche Mitteilung Folgendes enthalten:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Ihre physische Unterschrift oder elektronische Signatur;</li>
              <li>
                Die Identifizierung der Arbeit, die mit Urheberrechten oder
                sonstigen Rechten des geistigen Eigentums, welche mutmaßlich
                verletzt wurden, geschützt ist;
              </li>
              <li>
                Die Identifizierung des Materials, gegen das mutmaßlich
                verstoßen wurde, wobei die Identifizierung so detailliert sein
                muss, dass wir das besagte Material ausfindig machen können;
              </li>
              <li>
                Die geeignete Information, damit wir mit Ihnen in Kontakt treten
                können (einschließlich Ihrer Postanschrift, Telefonnummer und
                E-Mail-Adresse);
              </li>
              <li>
                Eine Erklärung, in der Sie guten Glaubens zum Ausdruck bringen,
                dass Sie der Meinung sind, dass die Verwendung des durch
                Urheberrechte oder andere Rechte des geistigen Eigentums
                geschützten Materials vom Inhaber, dem Vertreter des Inhabers
                oder dem Gesetz nicht erlaubt worden ist;
              </li>
              <li>
                Eine Erklärung, in der Sie festhalten, dass die in der
                schriftlichen Mitteilung enthaltene Information richtig ist; und
                &nbsp;
              </li>
              <li>
                Eine eidesstattliche Erklärung, in der Sie festhalten, dass Sie
                ermächtigt sind, um im Namen des Inhabers der Urheberrechte oder
                eines anderen Rechts des geistigen Eigentums zu handeln.
              </li>
            </ul>
            Wenn sich diese Mitteilung nicht auf eine Verletzung von
            Urheberrechten oder anderen Rechten des geistigen Eigentums bezieht,
            kann der DMCA-Vertreter von Ironhack bei dieser Sache nicht
            eingreifen.
          </Text3>
          <Text3 as="p">
            Sendung einer Gegendarstellung im Sinne des DMCA &nbsp;
          </Text3>
          <Text3 as="p">
            Sollten wir den Zugang zu dem von Urheberrechen geschützten
            Material, das Sie übermittelt haben, sperren oder einschränken,
            senden wir Ihnen eine Benachrichtigung, wenn für die besagte
            Löschung der gültige Erhalt einer Löschungsmitteilung in
            Übereinstimmung mit dem Digital Millennium Copyright Act, DMCA,
            vorgeschrieben ist. Sie können darauf reagieren, indem Sie dem
            DMCA-Vertreter von Ironhack eine schriftliche Gegendarstellung
            senden, die folgende Information enthält:
          </Text3>
          <Text3 as="p">
            <ol>
              <li>Ihre physische Unterschrift oder elektronische Signatur;</li>
              <li>
                Die Identifizierung des gelöschten Materials oder desjenigen, zu
                dem der Zugang eingeschränkt wurde, und Angabe der Stelle, wo
                das Material vor seiner Löschung oder vor der Einschränkung des
                Zugangs erschien;
              </li>
              <li>
                Eine eidesstattliche Erklärung, in der Sie festhalten, dass Sie
                guten Glaubens der Meinung sind, dass die Löschung des Materials
                oder die Einschränkung des Zugangs dazu infolge eines Fehlers
                oder eines Irrtums bei der Identifizierung des Materials, das
                gelöscht oder zu dem der Zugang eingeschränkt werden soll,
                erfolgt ist; &nbsp;
              </li>
              <li>
                Ihren Namen, Ihre Postanschrift und Ihre Telefonnummer sowie
                eine Erklärung, in der Sie festhalten, dass Sie sich dem Gericht
                des Bundesdistrikts, in dem sich Ihre Postanschrift befindet,
                oder, sollte Ihre Postanschrift nicht in den USA liegen,
                demjenigen jedes sonstigen Gerichtsbezirks unterwerfen, in dem
                wir uns befinden könnten, und dass Sie die Zustellung der Klage
                der Person, die die Mitteilung über das mutmaßlich
                rechtsverletzende Material gesendet hat, oder ihres Vertreters
                akzeptieren. &nbsp;
              </li>
            </ol>
            Sperrung im Fall von wiederholten Verstößen
          </Text3>
          <Text3 as="p">
            Wir behalten uns das Recht vor, nach unserem ausschließlichen
            Ermessen einen Nutzer, dem wiederholt Mitteilungen im Sinne des DMCA
            gesendet werden oder dem sonstige Verstöße zuzuschreiben sind, von
            einem Ironhack Bootcamp auszuschließen oder ihm den Zugang zu dieser
            Website oder einer Dienstleistung zu sperren. Die Mitteilungen und
            Gegendarstellungen müssen die jeweils geltenden und vom DMCA
            auferlegten gesetzlichen Erfordernisse erfüllen; mehr Information
            unter https://www.copyright.gov/. Bevor Sie eine Mitteilung oder
            Gegendarstellung einreichen, informieren Sie sich bei Ihrem
            Rechtsberater und unter Sektion 512 Titel 17 des United States
            Codes, da kraft des DMCA Sanktionen aufgrund falscher Beschwerden
            auferlegt werden.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">HAFTUNGSAUSSCHLUSS</Text2>
          <Text3 as="p">
            DIESE WEBSITE WIRD "WIE BESEHEN" UND "MIT ALLEN FEHLERN", DIE SIE
            ENTHALTEN KÖNNTE, BEREITGESTELLT, UND SIE ÜBERNEHMEN ALLE MIT IHRER
            QUALITÄT UND LEISTUNG ZUSAMMENHÄNGENDEN RISIKEN.
          </Text3>
          <Text3 as="p">
            WIR VERSPRECHEN NICHT, DASS DIE WEBSITE ODER IHR INHALT, DIE
            DIENSTLEISTUNGEN ODER FUNKTIONEN UNUNTERBROCHEN, IN ENTSPRECHENDER
            WEISE, SICHER UND FREI VON FEHLERN GEBOTEN WERDEN, UND AUCH NICHT,
            DASS DIE MÄNGEL BEHOBEN WERDEN.
          </Text3>
          <Text3 as="p">
            DIE WEBSITE UND IHR INHALT WERDEN “WIE BESEHEN” UND “SOWEIT
            VERFÜGBAR” BEREITGESTELLT.
          </Text3>
          <Text3 as="p">
            WIR KÖNNEN NICHT GEWÄHRLEISTEN, DASS DIE DATEIEN ODER DATEN, DIE SIE
            VON DER WEBSITE HERUNTERLADEN, KEINE COMPUTERVIREN ODER SONSTIGE
            MALWARE ODER DESTRUKTIVE ELEMENTE ENTHALTEN.
          </Text3>
          <Text3 as="p">
            WIR ERKENNEN KEINE AUSDRÜCKLICHE, IMPLIZITE ODER GESETZLICHE
            GARANTIE AN, EINSCHLIESSLICH DER GARANTIEN HINSICHTLICH DER
            GENAUIGKEIT DIESER WEBSITE UND U. A. DER IMPLIZITEN GARANTIEN ZU
            MARKTFÄHIGKEIT, ANGEMESSENHEIT FÜR EINEN KONKRETEN ZWECK ODER EINE
            KONKRETE VERWENDUNG, INHABERSCHAFT SOWIE NICHTVERLETZUNG VON RECHTEN
            DES GEISTIGEN EIGENTUMS.
          </Text3>
          <Text3 as="p">
            WIR WEISEN AUCH JEDE HAFTUNG FÜR DIE HANDLUNGEN, AUSLASSUNGEN ODER
            DAS VERHALTEN VON DRITTEN IN VERBINDUNG ODER IM ZUSAMMENHANG MIT
            IHRER VERWENDUNG DER WEBSITE ODER DER DIENSTLEISTUNGEN VON IRONHACK
            ZURÜCK.
          </Text3>
          <Text3 as="p">
            DIES BEDEUTET, DASS WIR NICHT VERSPRECHEN KÖNNEN, DASS DIE WEBSITE
            KEINE MÄNGEL ENTHÄLT.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ERGEBNISSE</Text2>
          <Text3 as="p">
            Wir bieten keine Garantie in Bezug auf die Ergebnisse, die durch die
            Verwendung dieser Website erreicht werden können, oder die
            Genauigkeit oder Zuverlässigkeit der über die Website erhaltenen
            Information. Kein Rat und keine Information, die mündlich oder
            schriftlich gegeben werden und Sie über diese Website oder von uns,
            unseren Filialen oder anderen Schwestergesellschaften erhalten
            haben, ist durch eine Garantie untermauert. Wir übernehmen keine Art
            von gerechtem Ausgleich.
          </Text3>
          <Text3 as="p">
            Wir bemühen uns, die besten Ausbilder und das beste Personal
            anzustellen, um Ihnen das bestmögliche Erlebnis im Ironhack Bootcamp
            zu bieten. Wir können einen spezifischen Ausbilder für das Ironhack
            Bootcamp zu Werbezwecken vorstellen, aber dies darf nicht als
            Empfehlung dieses Ausbilders oder als Garantie dafür ausgelegt
            werden, dass der Kurs, zu dem Sie sich anmelden, unter der Leitung
            dieses Ausbilders stehen wird. Wir geben keine Erklärungen über die
            Qualität oder Eigenschaft eines Ausbilders des Ironhack Bootcamp ab.
          </Text3>
          <Text3 as="p">
            Möglicherweise veröffentlichen wir manchmal als Werbung oder
            Promotion die Ergebnisse bestimmter Schüler des Ironhack Bootcamp
            auf dieser Website, in den sozialen Netzwerken oder
            Promotionzentren. Ironhack garantiert jedoch nie ein Ergebnis, eine
            Arbeit, ein Gehalt oder eine berufliche Ausrichtung. Das Ironhack
            Bootcamp ist kein Programm für Arbeitsvermittlung und gewisse
            Ergebnisse können nicht wiederholt werden. Wir bemühen uns,
            vollkommen transparent bei den Angaben über die Schüler von Ironhack
            und die vorherigen Ergebnisse zu sein, einschließlich der
            Durchschnittsnoten und Ausnahmen. Es ist unser Ziel, den Teilnehmern
            am Ironhack Bootcamp die erforderlichen Daten zur Verfügung zu
            stellen, damit sie verstehen, dass die in der Vergangenheit
            erreichten Ergebnisse keine Gewährleistung für zukünftige Ergebnisse
            sind.
          </Text3>
          <Text3 as="p">
            DER UMSTAND, DASS EIN SCHÜLER VON IRONHACK NACH DEM IRONHACK
            BOOTCAMP EINE GUTE ARBEIT GEFUNDEN HAT, GEWÄHRLEISTET NICHT, DASS
            SIE DASSELBE ERGEBNIS ERREICHEN WERDEN. DIE ERGEBNISSE HÄNGEN SEHR
            DAVON AB, WO SIE SICH BEFINDEN UND WIE SICH DIE MARKTBEDINGUNGEN
            DARSTELLEN, DIE SICH UNSERER KONTROLLE ENTZIEHEN. WIR GARANTIEREN
            KEIN ERGEBNIS.
          </Text3>
          <Text3 as="p">
            Wenn Sie ein Ironhack Bootcamp abgeschlossen haben, stellt dies
            nicht sicher, dass Sie dann ein Experte in dem Unterrichtsfach sind.
            Unsere Ausbilder und unser Personal haben die Aufgabe, die
            Ausbildung über das Ironhack Bootcamp zu leiten, aber einige Schüler
            schließen es nicht mit einem zufriedenstellenden Ergebnis ab. Einige
            Schüler, die das Ironhack Bootcamp abgeschlossen haben, finden keine
            Arbeit auf ihrem Gebiet. Einige Schüler schließen es nicht ab und
            einige erwerben im Ironhack Bootcamp nicht die Fähigkeiten und
            Kenntnisse des Unterrichtsfachs. Bevor Sie sich zu einem Ironhack
            Bootcamp anmelden, sollten Sie mit einem Programmdirektor von
            Ironhack über die verschiedenen Zentren und Termine,
            Zulassungsvoraussetzungen, Gebühren und sonstige Kosten sowie über
            Ihre eigenen Fähigkeiten sprechen.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">HAFTUNGSBESCHRÄNKUNG</Text2>
          <Text3 as="p">
            IN DEM UMFANG, DER IN DEN ANWENDBAREN GESETZEN ERLAUBT IST,
            ÜBERNEHMEN WIR KEINE HAFTUNG FÜR DEN SCHADEN, DEN SIE INFOLGE
            VISUALISIERUNG, KOPIE ODER HERUNTERLADEN DER SICH AUF DIESER WEBSITE
            BEFINDLICHEN MATERIALIEN ERLEIDEN. KEINESFALLS HAFTEN WIR FÜR DEN
            INDIREKTEN, AUSSERORDENTLICHEN, SPEZIELLEN, BEILÄUFIGEN ODER
            FOLGESCHADEN, DEN SCHADENERSATZ MIT ABSCHRECKENDER WIRKUNG ODER DEN
            SCHADENERSATZ MIT STRAFWIRKUNG (EINSCHLIESSLICH DES VERLUSTS VON
            DATEN, EINKÜNFTEN, GEWINNEN, NUTZUNGEN ODER SONSTIGEN FINANZIELLEN
            ERTRÄGEN), UNBESCHADET DER ART UND WEISE, WIE ER SICH ERGEBEN HAT,
            SOGAR IM FALLE, DASS WIR DAVON WISSEN, DASS SICH DIESER SCHADEN
            VERZEICHNEN KANN.
          </Text3>
          <Text3 as="p">
            In einigen Rechtsprechungen sind die Haftungsbeschränkungen nicht
            erlaubt und in diesen Fällen würden sie nicht zur Anwendung kommen.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ENTSCHÄDIGUNG</Text2>
          <Text3 as="p">
            Sie erklären sich damit einverstanden, Ironhack, deren
            Führungskräfte, Verwalter, Aktionäre, Angestellten, Vertreter,
            Filialen oder Partner im Zusammenhang mit jeder Art von Beschwerden,
            Klagen, Verlusten, Haftung oder Kosten (einschließlich der
            Anwaltsgebühren), die Dritte infolge von oder in Verbindung mit der
            Nutzung dieser Website fordern, zu entschädigen und von jeder Schuld
            oder Belastung zu befreien.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ÖRTLICHES RECHT AUSFUHRKONTROLLE</Text2>
          <Text3 as="p">
            Wir kontrollieren und führen diese Website an unserem Sitz in den
            USA und möglicherweise gibt es Teile dieser Website, die in anderen
            Ländern nicht angemessen sind oder nicht zur Verfügung stehen. Wenn
            Sie diese Website außerhalb der USA nutzen, sind Sie dafür
            verantwortlich, den anwendbaren Gesetzen Folge zu leisten. &nbsp;
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            BEILEGUNG VON STREITIGKEITEN UND SCHIEDSVERFAHREN VERZICHT AUF
            SAMMELKLAGE
          </Text2>
          <Text3 as="p">
            Lesen Sie diese Klausel aufmerksam, da sie sich auf Ihre
            gesetzlichen Rechte auswirkt. &nbsp;
          </Text3>
          <Text3 as="p">
            Diese Klausel über Beilegung von Streitigkeiten und
            Schiedsverfahren; Verzicht auf Sammelklage (diese “
            <strong>Klausel</strong>”) ermöglicht eine schnelle und wirksame
            Beilegung von allen Streitigkeiten, die sich zwischen Ihnen und uns
            ergeben können; zum Beispiel Klagen oder Kontroversen, die sich auf
            Verträge, Gesetze, Vorschriften, Anordnungen, Delikte (u. a.
            einschließlich Betrug, Fälschung, betrügerischer Gewinn oder
            Fahrlässigkeit) oder jede sonstige Theorie in Übereinstimmung mit
            materiellem Recht oder billigem Ermessen stützen, einschließlich der
            Gültigkeit, Zumutbarkeit oder Reichweite dieser Klausel (mit
            Ausnahme der Anwendbarkeit der nachstehenden Klausel über den
            Verzicht auf eine Sammelklage). Der Begriff “Streitigkeit” ist
            folglich im weitestgehenden Sinn, den die Gesetze erlauben,
            auszulegen und umfasst alle Klagen gegen andere Parteien im
            Zusammenhang mit den Dienstleistungen oder Produkten, die Ihnen
            angeboten oder in Rechnung gestellt werden (als unsere Lizenznehmer,
            Zulieferer, Vertriebshändler oder Drittverkäufer), wenn immer Sie im
            selben Verfahren Klage gegen uns erheben.
          </Text3>
          <Text3 as="p">
            In dieser Klausel ist festgesetzt, dass alle Streitigkeiten zwischen
            Ihnen und uns über ein bindendes Schiedsverfahren beizulegen sind,
            da die Akzeptierung dieser Bedingungen bedeutet, dass Sie auf das
            Recht auf Klageerhebung und die Möglichkeit, vor einem Richter oder
            Geschworenen zu erscheinen, verzichten. Wir ziehen dieses Verfahren
            vor, da wir der Meinung sind, dass ein Schiedsverfahren wirksamer
            und weniger polemisch ist als ein Prozess. Um alles klar zu stellen,
            ist zu sagen, dass bei einem Schiedsverfahren keine Richter und
            keine Geschworenen anwesend sind und die Wiederaufnahme vor einem
            Gericht eingeschränkt ist. Der Schiedsrichter muss sich nach dieser
            Vereinbarung richten und ist ermächtigt, dieselben Schäden und
            Lösungen festzulegen wie ein Gericht (einschließlich der
            Anwaltsgebühren). Sie können jedoch auf diese Klausel verzichten,
            und dies würde bedeuten, dass Sie das Recht und die Möglichkeit
            haben, Klagen vor einem Gericht, vor einem Richter oder Geschworenen
            zu erheben oder sich an einem Fall zu beteiligen oder dabei
            vertreten zu sein, der von anderen vor Gericht gebracht wird (u. a.
            einschließlich Sammelklagen). SIE UND WIR AKZEPTIEREN, DASS MIT
            AUSNAHME DESJENIGEN, WAS NACHFOLGEND ANGEGEBEN IST, ALLE
            STREITIGKEITEN – WIE WEITER OBEN DEFINIERT -, UND ZWAR SOWOHL DIE
            GEGENWÄRTIGEN ALS AUCH DIEJENIGEN, DIE SICH AUF HANDLUNGEN ODER
            AUSLASSUNGEN IN DER VERGANGENHEIT ODER IN DER ZUKUNFT STÜTZEN, IN
            ÜBEREINSTIMMUNG MIT DIESER KLAUSEL AUSSCHLIESSLICH UND ENDGÜLTIG IM
            RAHMEN EINES BINDENDEN SCHIEDSVERFAHRENS UND NICHT VOR GERICHT
            BEIGELEGT WERDEN.
          </Text3>
          <Text3 as="p">
            Beilegung einer Beschwerde vor dem Schiedsverfahren
          </Text3>
          <Text3 as="p">
            Bei allen – sowohl vor Gericht als auch im Rahmen eines
            Schiedsverfahrens behandelten - Streitigkeiten müssen Sie uns zuerst
            die Möglichkeit geben, sie beizulegen. Der erste Schritt ist, uns
            eine E-Mail an die folgende Adresse zu senden: &nbsp;
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a> &nbsp;
            und darin die folgende Information zu geben: (1) Ihr Name, (2) Ihre
            Anschrift, (3) eine schriftliche Beschreibung der Beschwerde und der
            Grundlage, auf die sich die Streitigkeit stützt, (4) eine
            Beschreibung der spezifischen Lösung, die Sie erwarten. Wenn wir die
            Streitfrage nicht innerhalb von fünfundvierzig (45) Tagen ab Erhalt
            Ihrer Mitteilung beilegen, können Sie die Streitigkeit einem
            Schiedsverfahren unterwerfen. Die Streitfrage kann nur unter den
            Umständen, die nachstehend beschrieben sind, vor Gericht gebracht
            werden.
          </Text3>
          <Text3 as="p">
            Ausschluss des Schiedsverfahrens / Recht auf Selbstausschluss
          </Text3>
          <Text3 as="p">
            Unbeschadet des Vorstehenden können Sie oder wir beschließen, eine
            Streitigkeit einem Gericht und nicht einem Schiedsverfahren zu
            unterwerfen, wenn: (a) die Streitigkeit die Erfordernisse erfüllt,
            um als geringfügige Beschwerde vor Gericht gebracht zu werden; oder
            (b) SIE AUF DIE SCHIEDSVERFAHREN INNERHALB VON 30 TAGEN NACH
            AKZEPTIERUNG DIESER VEREINBARUNG VERZICHTEN (der “
            <strong>Selbstausschlusstermin</strong>”). Sie können auf diese
            Schiedsklausel verzichten, indem Sie uns eine E-Mail an die Adresse
            &nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> mit der
            folgenden Information senden: 1) Ihrem Namen, (2) Ihrer Anschrift,
            (3) einer deutlich zum Ausdruck gebrachten Erklärung in dem Sinn,
            dass Sie eine sich zwischen Ihnen und uns ergebende Streitigkeit
            nicht über ein Schiedsverfahren beilegen wollen. Wir werden Ihre
            Entscheidung aber nicht persönlich nehmen und versprechen Ihnen,
            dass Ihr Entschluss in Bezug auf diesen Selbstausschluss
            hinsichtlich der Schiedsklausel keine negativen Auswirkungen auf
            Ihre Beziehung mit uns haben wird. Wir müssen jedoch den
            Selbstausschlusstermin anwenden, und aus diesem Grund bitten wir
            Sie, nicht zu vergessen, dass &nbsp;
            <strong>ein Antrag auf Selbstausschluss</strong>, &nbsp;
            <strong>
              den wir nach Ablauf des Selbstausschlusstermins erhalten, keine
              Wirksamkeit entfaltet und Sie dann Ihre Streitigkeiten einem
              Schiedsverfahren oder einem für geringfügige Beschwerden
              zuständigen Gericht unterwerfen müssen.
            </strong>
          </Text3>
          <Text3 as="p">Schiedsverfahren</Text3>
          <Text3 as="p">
            Wenn diese Klausel zur Anwendung kommt, aber die Streitigkeit nicht
            so beigelegt wird, wie weiter oben festgesetzt ist (Beilegung einer
            Beschwerde vor dem Schiedsverfahren), können sowohl Sie als auch wir
            die Schiedsverfahren einleiten. Die American Arbitration
            Association, “<strong>AAA</strong>”, www.adr.org, oder JAMS, &nbsp;
            <a href="http://www.jamsadr.com">www.jamsadr.com</a>, werden als
            Schiedsinstanz bei allen Streitfragen auftreten, und die
            Entscheidungen werden von einem Einzelschiedsrichter getroffen. Das
            Schiedsverfahren wird individuell eröffnet und nie als
            Sammelschiedsverfahren oder als repräsentative oder konsolidierte
            Klage oder repräsentatives oder konsolidiertes Schiedsverfahren
            geführt. Der Schiedsrichter trifft seine Entscheidungen über alle
            Themen, einschließlich des Umfangs dieser Schiedsklausel.
          </Text3>
          <Text3 as="p">
            Bei Schiedsverfahren vor der AAA finden, wenn die Streitigkeiten
            unter 75.000 $ liegen, die Supplementary Procedures for
            Consumer-Related Disputes (zusätzliche Verfahren für Streitigkeiten
            im Zusammenhang mit Verbrauchern) der AAA Anwendung; wenn sich die
            Streitigkeiten auf 75.000 $ belaufen oder höher sind, werden die
            Commercial Arbitration Rules (Handelsschiedsordnung) der AAA
            angewendet. In beiden Fällen kommen die Optional Rules For Emergency
            Measures Of Protection (optionale Bestimmungen für
            Notfall-Schutzmaßnahmen) der AAA zum Tragen. Die Bestimmungen der
            AAA stehen unter www.adr.org oder unter der Telefonnummer
            1-800-778-7879 zur Verfügung. Bei Schiedsverfahren vor der JAMS
            finden die Comprehensive Arbitration Rules &amp; Procedures
            (umfassende Schiedsordnung und -verfahren) sowie die Recommended
            Arbitration Discovery Protocols For Domestic, Commercial Cases
            (empfohlene Protokolle für Schiedsverfahren bei inländischen
            kommerziellen Fällen) der JAMS Anwendung. Die Bestimmungen der JAMS
            stehen unter www.jamsadr.com oder unter der Telefonnummer
            1-800-352-5267 zur Verfügung. Es wird in Übereinstimmung mit dieser
            Klausel vorgegangen, wenn es hinsichtlich der anwendbaren
            Schiedsvorschriften Widersprüche gibt. Keinesfalls finden auf das
            Schiedsverfahren die repräsentativen Vorschriften oder Verfahren
            oder die einer Sammelklage Anwendung.
          </Text3>
          <Text3 as="p">
            Da sich diese Website und diese Bedingungen auf den
            zwischenstaatlichen Handel auswirken, ist das Federal Arbitration
            Act, “<strong>FAA</strong>” (Bundesschiedsgesetz) für die Regelung
            der Schiedsverfahren in Bezug auf alle Streitigkeiten
            ausschlaggebend. Der Schiedsrichter wird jedoch nach seinem Ermessen
            das dementsprechende materielle Gesetz gemäß dem FAA und die
            anwendbaren Verjährungsfristen oder die aufschiebende Bedingung
            anwenden.
          </Text3>
          <Text3 as="p">
            <em>Schiedsspruch</em> – Der Schiedsrichter kann auf individuelle
            Weise jede nach den in Kraft stehenden Gesetzen mögliche
            Entschädigung zuweisen und ist nicht ermächtigt, eine Entschädigung
            für, gegen oder zugunsten einer Person zu gewähren, die beim
            Verfahren nicht als Partei gilt. Der Schiedsrichter fällt seinen
            Schiedsspruch schriftlich, er muss aber keine Erklärungen über
            dessen Gründe abgeben, es sei denn, eine Partei beantragt es oder
            dies ist in den anwendbaren Gesetzen vorgeschrieben. Die
            Entscheidung ist endgültig, rechtskräftig und bindend für die
            Parteien, mit Ausnahme der Berufungsrechte, die im FAA und anderen
            anwendbaren Gesetzen vorgesehen sind. Sie können vor jedem Gericht
            ausgeübt werden, das für die Parteien in Bezug auf Erfüllung
            zuständig ist.
          </Text3>
          <Text3 as="p">
            <em>Schiedsort</em> – Sie oder wir können das Schiedsverfahren
            sowohl in Florida als auch in dem Bundesgerichtsbezirk einleiten,
            der aufgrund Ihrer Anschrift auf der Rechnung entspricht.
          </Text3>
          <Text3 as="p">
            <em>Zahlung der Gebühren und der Kosten des Schiedsverfahrens </em>–
            Wenn immer Sie dies schriftlich vor dem Schiedsverfahren beantragt
            haben, übernehmen wir die Zahlung aller Kosten für die Einleitung
            des Schiedsverfahrens und die der Termine vor der AAA oder der JAMS,
            und auch die Gebühren, Kosten und Aufwendungen des Schiedsrichters,
            wenn Sie dies beim dementsprechenden Schiedsrichter vor oder während
            des ersten Beweistermins des Schiedsverfahrens schriftlich beantragt
            haben. Sie haften jedoch für alle Kosten und zusätzlichen Ausgaben,
            die sich für Sie während des Schiedsverfahrens ergeben, wobei dies
            u. a. die Gebühren der Anwälte und Sachverständigen umfasst.
            Abgesehen von den in Übereinstimmung mit den anwendbaren Gesetzen
            erstattungsfähigen Kosten und Ausgaben sind Sie, wenn Sie uns
            benachrichtigen und guten Glaubens mit uns verhandeln, wie unter dem
            weiter oben behandelten Abschnitt mit dem Titel “Beilegung einer
            Beschwerde vor dem Schiedsverfahren” festgesetzt ist, und wenn der
            Schiedsrichter bestimmt, dass Sie die obsiegende Partei bei diesem
            Schiedsverfahren sind, berechtigt, die vertretbaren Gebühren und
            Ausgaben der Anwälte in Übereinstimmung mit dem Schiedsspruch
            zurückerstattet zu bekommen. &nbsp;
          </Text3>
          <Text3 as="p">Verzicht auf Sammelklage</Text3>
          <Text3 as="p">
            Wenn nicht in dieser Schiedsklausel Gegenteiliges festgesetzt ist,
            so kann der Schiedsrichter die Beschwerden von mehr als einer Person
            nicht zusammenlegen und keine andere Art von Sammelklage oder
            Sammelverfahren oder repräsentative Klage oder repräsentatives
            Verfahren führen (wie z. B. eine Sammelklage, eine zusammengelegte
            Klage, eine repräsentative Klage oder eine Privatklage), es sei
            denn, Sie und wir vereinbaren dies spezifisch und schriftlich nach
            Einleitung des Schiedsverfahrens. Sollten Sie beschließen, nach
            Ihrem Selbstausschluss hinsichtlich dieser Schiedsklausel die
            Streitfrage vor Gericht zu bringen, wie weiter oben angegeben ist,
            findet dieser Verzicht auf Sammelklage keine Anwendung auf Sie.
            Weder Sie noch andere Nutzer dieser Website können Vertreter einer
            Gruppe oder Mitglieder einer Gruppe sein oder sich auf irgendeine
            andere Weise an einem Sammelverfahren, konsolidierten oder
            repräsentativen Verfahren beteiligen, ohne die weiter oben genannten
            Erfordernisse in Bezug auf Selbstausschluss erfüllt zu haben.
          </Text3>
          <Text3 as="p">Verzicht auf Geschworene</Text3>
          <Text3 as="p">
            Sie verstehen und sind damit einverstanden, dass, wenn Sie die
            Schiedsklausel dieser Bedingungen akzeptieren, Sie und wir auf das
            Recht auf einen Prozess mit Geschworenen oder auf einen Prozess vor
            einem Richter in einem öffentlichen Gericht verzichten. Würde es
            diese Schiedsklausel nicht geben, wären Sie und wir berechtigt
            gewesen oder hätten die Möglichkeit gehabt, Streitigkeiten einem
            Gericht vor einem Richter oder Geschworenen zu unterbreiten oder an
            einem Fall beteiligt oder vertreten zu sein, den andere vor Gericht
            bringen (einschließlich Sammelklagen). Diese Rechte gelten als
            aufgehoben, sollte weiter unten nicht das Gegenteil angegeben sein.
            Und auch andere Rechte, die Ihnen entsprochen hätten, wenn Sie vor
            Gericht gehen (z. B. die Berufungsrechte und bestimmte Arten von
            Ermittlungen), können eingeschränkt oder ebenfalls aufgehoben
            werden.
          </Text3>
          <Text3 as="p">Teilbarkeit</Text3>
          <Text3 as="p">
            Sollte eine der Bestimmungen dieser Schiedsklausel (mit Ausnahme der
            Klausel über den Verzicht auf Sammelklage) als gesetzwidrig oder
            unanwendbar gelten, so wird diese Bestimmung aus der Schiedsklausel
            entfernt, wobei jedoch der Rest seine Gültigkeit und Wirksamkeit
            bewahrt. Wenn die Klausel über den Verzicht auf Sammelklage als
            gesetzwidrig oder unanwendbar gilt, ist die gesamte Schiedsklausel
            unanwendbar und die Streitigkeit wird vor Gericht beigelegt.
          </Text3>
          <Text3 as="p">Kontinuität</Text3>
          <Text3 as="p">
            Diese Schiedsklausel bleibt gültig, auch nachdem Sie Ihr Ironhack
            Bootcamp bei uns oder unseren Filialen abgeschlossen haben und diese
            Website nicht mehr nutzen. Auch wenn in diesen Bedingungen
            Gegenteiliges festgesetzt ist, so sind wir damit einverstanden, dass
            Sie, sollten wir diese Schiedsklausel in irgendeiner Weise ändern
            (mit Ausnahme der Änderungen in Bezug auf die Adressen für
            Benachrichtigungen), die besagte Änderung ablehnen und von uns
            verlangen können, sich auf die Fassung dieser Klausel berufen zu
            können, sollte sich eine Streitfrage ergeben.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SPRACHE</Text2>
          <Text3 as="p">
            Die betroffenen Parteien haben ausdrücklich verlangt, dass diese
            Vereinbarung und alle damit in Verbindung stehenden Dokumente und
            Benachrichtigungen auf Englisch sein sollen.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ALLGEMEINES</Text2>
          <Text3 as="p">
            Wir sind der Meinung, dass eine direkte Kommunikation die meisten
            Probleme löst (sollten wir den Eindruck haben, dass Sie diesen
            Bedingungen nicht Folge leisten, so werden wir Sie benachrichtigen).
            Und da wir unserer Beziehung einen hohen Wert beilegen, werden wir
            Ihnen erforderliche Korrekturmaßnahmen empfehlen.
          </Text3>
          <Text3 as="p">
            Gewisse Verletzungen dieser Bedingungen können jedoch nach unserem
            Ermessen dazu führen, dass Ihr Zugang zu dieser Website unverzüglich
            gesperrt werden muss, ohne dass wir Sie vorher davon in Kenntnis
            setzen. Das Bundesschiedsgesetz, die Gesetze des Staates Florida und
            die anwendbaren Bundesgesetze der USA regeln diese Bedingungen,
            unbeschadet einer Wahl der Gesetzesbestimmungen oder
            Gesetzeskollision. Es finden keine ausländischen Gesetze Anwendung.
            Mit Ausnahme der weiter oben beschriebenen Streitigkeiten, die einem
            Schiedsverfahren unterworfen werden, wird jede Streitfrage im
            Zusammenhang mit diesen Bedingungen oder dieser Website vor die
            Gerichte in Miami-Dade County, Florida, gebracht werden. Sollte ein
            zuständiges Gericht der Meinung sein, dass eine der Bestimmungen
            dieser Bedingungen unwirksam, inkompatibel oder unanwendbar ist,
            wird eine solche Bestimmung in dem unbedingt erforderlichen Umfang
            eingeschränkt oder entfernt und durch eine gültige Bestimmung
            ersetzt, die die Absichten dieser Bedingungen in bestmöglicher Weise
            darstellt, sodass sie weiterhin gültig und anwendbar sind und keine
            weiteren Bedingungen zu ändern sind. Sollten diese Bedingungen nicht
            zur Anwendung kommen, so bedeutet dies keinen Verzicht auf unsere
            Rechte. Diese Bedingungen stellen die komplette Vereinbarung
            zwischen Ihnen und uns dar und ersetzen somit jede Verhandlung,
            Debatte oder Vereinbarung, die vorher oder gegenwärtig zwischen
            irgendwelchen Personen über diese Website geführt bzw. getroffen
            wurde. Die Rechte an geistigem Eigentum, der Verzicht auf Garantien,
            Ihre Erklärungen, Entschädigungen, Haftungsbeschränkungen und die
            allgemeinen Bestimmungen behalten nach Beendigung dieser Bedingungen
            ihre Gültigkeit.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">MITTEILUNG AN VERBRAUCHER IN KALIFORNIEN</Text2>
          <Text3 as="p">
            In Übereinstimmung mit Sektion 1789.3 des Bürgerlichen Gesetzbuches
            Kaliforniens haben die Nutzer ein Recht auf die folgende Mitteilung
            ihrer Verbraucherrechte: Provider dieser Website und Erbringer der
            Dienstleistungen ist Ironhack, 990 Biscayne Blvd, Ste. 502, Miami FL
            33132. Sollten Sie eine Frage oder Beschwerde im Zusammenhang mit
            dieser Website oder den Dienstleistungen haben, können Sie sich über
            die E-Mail-Adresse &nbsp;
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a> direkt an
            uns wenden. Sie können auch ein Schreiben an Ironhack, 990 Biscayne
            Blvd, Ste. 502, Miami FL 33132, senden. Die Einwohner Kaliforniens
            können sich auch an die Verbraucherschutzstelle der Abteilung für
            Verbraucherfragen in Kalifornien über die Postanschrift 1625 North
            Market Blvd., Sacramento, CA 95834, oder unter der Telefonnummer
            (916) 445-1254 oder (800) 952-5210, Hörgeschädigte unter TDD (800)
            326-2297 oder TDD (916) 322-1700, wenden.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">KOMMENTARE</Text2>
          <Text3 as="p">
            Alles, was Sie uns in irgendeiner Kommunikationsform (z. B.
            Telefonanruf, Kommentare im Blog, E-Mails) senden (z. B. Kommentare,
            Bescheinigungen, Zitate, Fragen, Vorschläge, Material, alles
            gemeinsam die “<strong>Kommentare</strong>”), gilt als nicht
            vertraulich und steht nicht im Alleineigentum. Sollten die Gesetze
            nichts Gegenteiliges vorschreiben, treten Sie hiermit alle Rechte,
            Ansprüche und Interessen in Bezug auf jede – patentierbare oder
            nicht patentierbare - Idee, Erkenntnis, Konzept, Technik oder jedes
            sonstige geistige Eigentum oder Urheberrechte, die in den
            Kommentaren inbegriffen sind, ab und wir können sie auf der
            Grundlage der besagten Kommentare in freier Weise, ohne irgendeine
            Zuweisung oder Gegenleistung an Sie für jeden Zweck auch immer
            verwenden, u. a. einschließlich der Entwicklung, der gegenwärtigen,
            vergangenen oder zukünftigen Herstellung, der Lizenzen, der
            Vermarktung und des direkten oder indirekten Verkaufs von Produkten
            und Dienstleistungen. In den Fällen, in denen die weiter oben
            genannte Abtretung gesetzlich verboten ist, gewähren Sie uns hiermit
            eine ausschließliche, übertragbare, internationale, gebührenfreie
            und voll bezahlte Lizenz (einschließlich des Rechts, Unterlizenzen
            zu geben), um alle Kommentare so zu verwenden und zu verwerten, wie
            wir in unserem ausschließlichen Ermessen entscheiden. Unbeschadet
            des Vorstehenden verstehen Sie und akzeptieren, dass wir nicht
            verpflichtet sind, solche Ideen, Erkenntnisse, Konzepte oder
            Techniken, die in den Kommentaren enthalten sind, zu verwenden, zu
            veröffentlichen, wiederzugeben oder zu verbreiten, und dass Sie
            nicht das Recht haben, eine solche Verwendung, Veröffentlichung,
            Wiedergabe oder Verteilung zu verlangen. &nbsp;
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">KONTAKT</Text2>
          <Text3 as="p">
            Sollten Sie eine Frage zu diesen Bedingungen haben oder sich aus
            anderen Gründen mit uns in Verbindung setzen wollen, steht Ihnen
            dafür die Adresse &nbsp;
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a> zur
            Verfügung. &nbsp;
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "de" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('de')(TermOfUse);
